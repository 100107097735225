.Header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    min-height: 225px;
}

.Header-name {
    text-align: center;
    font-size: 60px;
    text-transform: uppercase;
}

.Header-subtitle {
    font-size: 20px;
    font-style: italic;
    text-transform: uppercase;
}