.SideBar {
    background-color: #f1f1f1;
    text-align: right;
    display: flex;
    flex-direction: column;
    padding-left: 3em;
}

.SideBar-label-with-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.SideBar-contact-label {
    display: flex;
    align-items: center;
}

.SideBar-contact-icon {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0.25em;
}

.SideBar-skills-subtitle {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
}
