.MainExperience {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding-bottom: 10rem;
    padding-left: 1em;
    padding-right: 5vw;
    max-width: 650px;
}

.MainExperience-title {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0 0 0;
    text-transform: uppercase;
}

.MainExperience-position {
    font-size: 16px;
    font-weight: bold;
}

.MainExperience-list {
    margin-top: 0;
}
