.SideBlock {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.SideBlock > div {
    display: flex;
    justify-content: right;
}

.SideBlock-title {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0 0 0;
    text-transform: uppercase;
}